import api from '@/api/api'

export default {
  getTrainerSurveyEntries({ commit, state, getters }) {
    // recursive fonction
    const fetchEntries = (url, accumulatedEntries = []) => {
      // Replace/api/ in case the URL comes from previous results
      const cleanUrl = url.replace('/api/', '')

      return api.auth.get(cleanUrl).then(response => {
        // Add new entries to previous ones
        const combinedEntries = accumulatedEntries.concat(response.items)

        // If has_next is true (there is a next page), call recursively
        if (response.has_next) {
          return fetchEntries(response.next, combinedEntries) // Use response.next to paginate
        }

        // If has_next is false, return all combined entries
        return combinedEntries
      })
    }

    // Start paging with the initial URL
    return fetchEntries('trainer-survey-entries')
      .then(allEntries => {
        // Commit to Vuex once all data is retrieved
        commit('SET_CURRENT_TRAINER_SURVEY_ENTRIES', allEntries)
        return Promise.resolve(allEntries)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}
