import api from '@/api/api'
import { PAGINATION, PAGINATION_TRAINEE } from '@/utils/consts'
import { sentryCustomMessage } from '@/interface/sentryInterface'

export default {
  getTrainings({ commit, rootState }, { searchString, page }) {
    return api.auth
      .get(`trainer/trainings`, {
        search_term: searchString,
        page: page,
        limit: PAGINATION
      })
      .then(result => {
        commit('SET_MAX_TRAININGS', result.total)
        commit('SET_TRAININGS', result.items)
        return Promise.resolve(result.items)
      })
      .catch(() => {
        return Promise.reject()
      })
  },
  getSessions({ commit, rootState }, { trainingId, searchString, page }) {
    return api.auth
      .get(`trainer/sessions`, {
        training_id: trainingId,
        search_term: searchString,
        page: page,
        limit: PAGINATION
      })
      .then(result => {
        commit('SET_MAX_SESSIONS', result.total)
        commit('SET_SESSIONS', result.items)
        return Promise.resolve(result.items)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  setTraineeCash({ commit, rootState }, newList) {
    commit('SET_TRAINEE', newList)
  },
  getTrainee({ commit, rootState }, { searchTerm, page }) {
    return api.auth
      .get(`trainees`, {
        search_term: searchTerm,
        page: page,
        limit: PAGINATION_TRAINEE
      })
      .then(({ items, total }) => {
        commit('SET_MAX_TRAINEE', total)
        commit('SET_TRAINEE', items)

        return Promise.resolve(items)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  setTrainings({ commit, rootState }, { title, code }) {
    if (code === '') {
      code = 'auto'
    }

    return api.auth
      .post(`trainer/trainings`, {
        title: title,
        code: code
      })
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  setSession({ commit, rootState }, { trainingId, code, startAt, endAt }) {
    if (code === '') {
      code = 'auto'
    }
    return api.auth
      .post(`trainer/sessions`, {
        training_id: trainingId,
        code: code,
        start_at: startAt,
        end_at: endAt
      })
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  async setSection({ commit, dispatch, rootState }, newSection) {
    return new Promise((resolve, reject) => {
      api.auth
        .post(`trainer/sections`, {
          session_id: newSection.doNotEdit.id,
          code:
            newSection.editCode.code === '' ? 'auto' : newSection.editCode.code,
          name: newSection.editName.name,
          type: newSection.editType.type,
          mode: newSection.editLocation.mode.type,
          location: newSection.editLocation.location,
          address: newSection.editLocation.address,
          zipcode: newSection.editLocation.zipcode,
          city: newSection.editLocation.city,
          country: newSection.editLocation.country,
          start_at: newSection.editPlanning.start_at, //require
          end_at: newSection.editPlanning.end_at, //require
          auto_create_sequences: newSection.editPlanning.auto_create_sequences,
          sign_before_slot_start_delay:
            newSection.editOptions.sign_before_slot_start_delay,
          morning_start: newSection.editPlanning.morning_start,
          morning_end: newSection.editPlanning.morning_end,
          afternoon_start: newSection.editPlanning.afternoon_start,
          afternoon_end: newSection.editPlanning.afternoon_end,
          tags: newSection.editTags.tags.length
            ? newSection.editTags.tags
            : [''],
          site_id: newSection.editOptions.site.id,
          timezone: newSection.editPlanning.timezone
        })
        .then(result => {
          return resolve(result)
        })
        .catch(error => {
          if (error.data.status === 400) {
            dispatch('utils/setErrorData', error.data.violations, {
              root: true
            })
          } else {
            sentryCustomMessage('update section error', {
              errorData: error.data
            })
            dispatch('utils/setCommonError', error, { root: true })
          }
          return reject(error)
        })
    })
  },
  resetTrainee({ commit }) {
    commit('SET_TRAINEE', [])
    commit('SET_MAX_TRAINEE', 0)
  }
}
