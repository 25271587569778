import { SURVEY_TYPE } from '@/utils/consts'
import api from '@/api/api'

export default {
  getSectionSurveyEntries({ commit, state }, { sectionId }) {
    return api.auth
      .get(`sections/${sectionId}/all-survey-entries`)
      .then(surveys => {
        const finalSurveys = surveys.items.filter(
          survey =>
            survey.survey_anonymous_response === false &&
            survey.survey_type !== 'trainer_survey' // temporaire car à terme c’est de refaire cette vue pour inclure les réponses des animateur
        )
        commit('SET_CURRENT_SURVEYS', finalSurveys)
        return Promise.resolve()
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  sendSurveyCollectiveSign({ commit, state }, data) {
    return api.auth
      .post(`sections/${data.sectionId}/survey/collective`, {
        survey_id: state.current_select_survey.id,
        registration_ids: data.registration_ids
      })
      .then(({ url }) => {
        return Promise.resolve(url)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  setCurrentStatus({ commit }, state) {
    commit('SET_CURRENT_STATUS', state)
  },

  setOldStatusSurvey({ commit }, state) {
    commit('SET_OLD_STATUS', state)
  },
  setCurrentSurveys({ commit }, state) {
    commit('SET_CURRENT_SURVEYS', state)
  },
  setCurrentSelectEvaluation({ commit }, state) {
    commit('SET_CURRENT_SELECT_EVALUATION', state)
  },
  setCurrentSelectSurvey({ commit }, state) {
    commit('SET_CURRENT_SELECT_SURVEY', state)
  },
  setCurrentSelectTrainee({ commit }, state) {
    commit('SET_CURRENT_SELECT_TRAINEE', state)
  },
  postEvaluationsEntries(
    { commit, state },
    { sectionId, surveyId, registrationsIds }
  ) {
    return api.auth
      .post(`sections/${sectionId}/evaluations-entries`, {
        survey_id: surveyId,
        registrations_ids: registrationsIds
      })
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  getSurveyFromSection({ commit, state }, { sectionId }) {
    return api.auth
      .get(`sections/${sectionId}/surveys`)
      .then(res => {
        let surveys = []
        let evals = []
        res.forEach(item => {
          if (item.type === SURVEY_TYPE.SURVEY) {
            surveys.push(item)
          } else if (item.type === SURVEY_TYPE.EVALUATION) {
            evals.push(item)
          }
        })
        commit('SET_CURRENT_SURVEYS', surveys)
        commit('SET_CURRENT_EVALUATIONS', evals)
        return Promise.resolve()
      })
      .catch(e => {
        return Promise.reject(e)
      })
  }
}
